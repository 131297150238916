import { nextProjectUrl } from '@shared/constants/urls';

import { NavLayoutLinkBase, PrimaryNavItem } from './types';

export const storeQuickNavItems: NavLayoutLinkBase[] = [
  {
    id: 'discover',
    label: 'Discover',
    href: nextProjectUrl.store('/'),
  },
  {
    id: 'apps',
    label: 'Apps',
    href: nextProjectUrl.store('/apps'),
  },
  {
    id: 'games',
    label: 'Games',
    href: nextProjectUrl.store('/games'),
  },
];

export const storePrimaryNav: PrimaryNavItem[] = [
  ...storeQuickNavItems,
  {
    id: 'aiAgents',
    label: 'AI Agents',
    href: nextProjectUrl.store('/ai-agents'),
  },
  {
    id: 'memes',
    label: 'Memes',
    href: nextProjectUrl.store('/memes'),
  },
  {
    id: 'telegramMiniApps',
    label: 'Telegram Mini-Apps',
    href: nextProjectUrl.store('/telegram-mini-apps'),
  },
  {
    id: 'upcoming',
    label: 'Upcoming',
    href: nextProjectUrl.store('/upcoming'),
  },
  // {
  //   id: 'sub-test',
  //   label: 'Sub',
  //   children: [
  //     {
  //       id: 'upcoming',
  //       label: 'Upcoming',
  //       href: nextProjectUrl.store('/upcoming'),
  //     },
  //   ]
  // }
];

export const launchpadTokenSalesNavItem: PrimaryNavItem = {
  id: 'sale',
  label: 'Token Sales',
  href: nextProjectUrl.launchpad('/token-sales'),
};

export const launchpadCampaignsNavItem: PrimaryNavItem = {
  id: 'campaigns',
  label: 'Campaigns',
  href: nextProjectUrl.launchpad('/campaigns'),
};

export const launchpadMyAccountNavItem: PrimaryNavItem = {
  id: 'account',
  label: 'My Account',
  href: nextProjectUrl.launchpad('/portfolio'),
  isAuthRequired: true,
};

export const launchpadHowItWorksNavItem: PrimaryNavItem = {
  id: 'how-it-works',
  label: 'How it Works',
  href: nextProjectUrl.launchpad('/how-it-works'),
};

export const launchpadPrimaryNav: PrimaryNavItem[] = [
  launchpadMyAccountNavItem,
  launchpadTokenSalesNavItem,
  launchpadHowItWorksNavItem,
  // {
  //   id: 'blog',
  //   label: 'Blog',
  //   href: '#'
  // }
];

export const stakingPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'staking',
    label: 'Staking',
    href: nextProjectUrl.staking('/'),
  },
  {
    id: 'how-it-works',
    label: 'How It Works',
    href: 'https://docs.magicsquare.io/sqr-token/sqr-token/staking-usdsqr-tokens-with-magic-square',
    target: '_blank',
    external: true,
  },
];

export const sqrHubPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'buy-sqr-on-dex',
    label: 'Buy SQR on DEX',
    href: 'https://pancakeswap.finance/?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x2B72867c32CF673F7b02d208B26889fEd353B1f8&chain=bsc',
    target: '_blank',
    external: true,
  },
  {
    id: 'buy-sqr-on-cex',
    label: 'Buy SQR on CEX',
    href: 'https://partner.bybit.com/b/sqrspot',
    target: '_blank',
    external: true,
  },
  {
    id: 'sqr-staking',
    label: 'SQR Staking',
    href: 'https://magicsquare.io/staking/',
    target: '_blank',
    external: true,
  },
  {
    id: 'magic-swap',
    label: 'Magic Swap',
    href: 'https://magicsquare.io/swap/',
    target: '_blank',
    external: true,
  },
  {
    id: 'magic-dao',
    label: 'Magic DAO',
    href: 'https://dao.magicsquare.io/ ',
    target: '_blank',
    external: true,
  },
];

export const swapPrimaryNav: PrimaryNavItem[] = [
  {
    id: 'swap',
    label: 'Swap',
    href: nextProjectUrl.swap('/'),
  },
  {
    id: 'how-it-works',
    label: 'How It Works',
    href: 'https://docs.magicsquare.io/for-users/magic-swap',
    target: '_blank',
    external: true,
  },
];

export const magicsquarePrimaryNav: PrimaryNavItem[] = [
  {
    id: 'store',
    label: 'Store',
    children: storePrimaryNav,
  },
  {
    id: 'launchpad',
    label: 'Launchpad',
    children: launchpadPrimaryNav,
  },
  {
    id: 'sqr-hub',
    label: 'SQR Hub',
    children: sqrHubPrimaryNav,
  },
  {
    id: 'knowledge-base',
    label: 'Knowledge Base',
    href: 'https://docs.magicsquare.io/',
    target: '_blank',
  },
  {
    id: 'blog',
    label: 'Blog',
    href: 'https://magicsquare.io/blog ',
  },
];

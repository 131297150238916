import { MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import { nextProjectUrl } from '@shared/constants/urls';
import { GENERAL_URLS } from '@shared/constants/urls';

export const products = [
  {
    id: 'store',
    label: 'Magic Store',
    href: nextProjectUrl.store('/'),
  },
  // {
  //   id: 'hot-offers',
  //   label: 'Hot Offers',
  //   href: nextProjectUrl.store('/hot-offers'),
  // },
  // {
  //   id: 'magic-board',
  //   label: 'Magic Board',
  //   href: nextProjectUrl.store('/magic-board'),
  // },
  // {
  //   id: 'reward-pools',
  //   label: 'Reward Pools',
  //   href: nextProjectUrl.store('/reward-pools'),
  // },
  {
    id: 'launchpad',
    label: 'Magic Launchpad',
    href: nextProjectUrl.launchpad('/'),
    mixPanelEventName: MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK,
  },
  {
    id: 'staking',
    label: 'Magic Staking',
    href: nextProjectUrl.staking('/'),
    mixPanelEventName: MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK,
  },
  {
    id: 'swap',
    label: 'Magic Swap',
    href: process.env.NEXT_PUBLIC_SWAP_HOST || 'https://magicsquare.io/swap',
  },
  {
    id: 'boost',
    label: 'Magic DAO',
    href: GENERAL_URLS.dao,
  },
];

export const developers = [
  {
    id: 'list-project',
    label: 'List your Project on the Magic Store',
    href: 'https://magicsquare.io/submit-your-app/',
  },
  {
    id: 'apply-for-ido-launchpad',
    label: 'Apply for IDO on the Magic Launchpad',
    href: 'https://forms.gle/CHimD4wFZ2Y78wov8',
  },
  {
    id: 'advertise-with-us',
    label: 'Advertise with Us',
    href: 'https://forms.gle/qWoJaJzk8CKdagXQ9',
  },
  // {
  //   id: 'run-campaign',
  //   label: 'Run a Campaign',
  //   href: 'https://docs.magic.store/for-project-owners-developers/how-do-you-run-a-hot-offer-campaign',
  // },
  // {
  //   id: 'widget',
  //   label: 'Score Widget',
  //   href: 'https://docs.magic.store/for-project-owners-developers/how-to-add-app-widgets',
  // },
  {
    id: 'apply-for-grant',
    label: 'Apply for a Grant',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSeOc1yitrOBkGal-SAgAPky5OQ0a8RiJM0_dbxEXXdC0z3RNQ/viewform',
  },
];

export const company = [
  {
    id: 'about',
    label: 'About Us',
    href: 'https://docs.magicsquare.io/',
  },
  {
    id: 'release-notes',
    label: 'Release Notes',
    href: 'https://docs.magicsquare.io/support/release-notes',
  },
  // {
  //   id: 'roadmap',
  //   label: 'Roadmap',
  //   href: 'https://docs.magic.store/roadmap/roadmap',
  // },
  // {
  //   id: 'lite-paper',
  //   label: 'Lite paper',
  //   href: 'https://magicsquare.gitbook.io/magic-square-litepaper',
  // },
  {
    id: 'blog',
    label: 'Blog',
    href: 'https://magicsquare.io/blog',
  },
  {
    id: 'knowledge-base',
    label: 'Knowledge base',
    href: 'https://docs.magicsquare.io/',
  },
  {
    id: 'media-kit',
    label: 'Media Kit',
    href: 'https://docs.magicsquare.io/library/brand-and-logos',
  },
];

export const contacts = [
  {
    id: 'official-links',
    label: 'Official links',
    href: 'https://link3.to/magicsquare',
  },
  {
    id: 'community-support',
    label: 'Community Support',
    href: 'https://docs.magicsquare.io/support/contact-us/customer-support',
  },
  {
    id: 'business-enquiries',
    label: 'Business enquiries',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSdvghj8pQkSfJl-KNQh7VKs5Zmq40OGPzICraYtHXTIg5SxFw/viewform?usp=send_form',
  },
  {
    id: 'career',
    label: 'Careers opportunities',
    href: 'https://career.magicsquare.io/',
  },
  {
    id: 'feedback',
    label: 'Give us feedback',
    href: 'https://magicsquare.featureos.app/',
  },
  // {
  //   id: 'apply-kol',
  //   label: 'Apply as a KOL',
  //   href: 'https://forms.gle/9c1ThUzLUka1wK2KA',
  // },
  // {
  //   id: 'support',
  //   label: 'User enquiries & Support',
  //   href: 'https://discord.com/invite/magicsquareio',
  // },
  // {
  //   id: 'business',
  //   label: 'Business enquiries',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSdvghj8pQkSfJl-KNQh7VKs5Zmq40OGPzICraYtHXTIg5SxFw/viewform?usp=send_form',
  // },
];

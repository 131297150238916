import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-primary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-secondary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/layouts/Main/Main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/components/providers/GlobalModalsMagicsquare/GlobalModalsMagicsquare.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/magicsquare/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalModalsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStateProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalStateProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAdSenseProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleAdSenseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleTagManagerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MixPanelProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/MixPanelProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/PasskeyProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/socket/SocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TonProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/UserAgentDetectProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/Web3Provider/Web3Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/utilities/src/providers/ReactQuery/QueryProviderSsr.tsx");

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

import { useIsClient } from 'usehooks-ts';

import { subscribeToMagicIdNewsletter } from '@api';

import { cn } from '@ui/uikit/utils';

const NewsletterSubscriptionForm = () => {
  const isClient = useIsClient();

  const { mutateAsync } = useMutation({
    mutationFn: (email: string) =>
      // according to the requirements, the group is fixed to 'MAGIC_STORE' for the footer subscription
      subscribeToMagicIdNewsletter({ json: { email, group: 'MAGIC_STORE' } }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{ email: string }>();

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await mutateAsync(email);

      toast.success('Subscription successful!');
      reset();
    } catch (error) {
      toast.error('Subscription unsuccessful!');
    }
  });

  return (
    <div>
      <p className="text-caption-sm-a text-base-text-tertiary mb-3">
        Subscribe & Empower Your Future!
      </p>

      <form className="flex flex-col md-msq:flex-row gap-3 mb-10" onSubmit={onSubmit}>
        <input
          type="email"
          placeholder="Email"
          className="msq-input py-1 w-full"
          required
          {...register('email')}
        />
        {isClient && (
          <button
            type="submit"
            className={cn('msq-btn msq-btn-secondary px-[1.46875rem] msq-btn-lg', {
              'msq-btn-loading': isSubmitting,
            })}
          >
            Subscribe
          </button>
        )}
      </form>
    </div>
  );
};

export { NewsletterSubscriptionForm };
